<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="loading">
			<v-row class="pa-11 pb-3 justify-space-start align-center">
				<h2>Dashboard Views - {{ heads.length }} Total</h2>
				<v-dialog v-model="dialog" max-width="650px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn elevation="0" small fab v-bind="attrs" v-on="on" color="pink" class="mx-3">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<NewDashView
						@dvAdded="UpdateDVs" 
						@CloseDialog="dialog = false"
					/>
				</v-dialog>
			</v-row>

			<v-row class="pa-6 pt-0">
				<v-col sm="12" md="6" lg="4" xl="3" cols="12" v-for="head in heads" :key="head.id">
					<EditDashView 
						:head="head"
						@dvDeleted="UpdateDVs"
						@dvUpdated="UpdateDVs"
					/>
				</v-col>
			</v-row>
		</v-card>
	</v-col>
</template>

<script>
import EditDashView from "./EditDashView";
import NewDashView from "./NewDashView";

export default {
	components: {
		EditDashView,
		NewDashView
	},

	data() {
		return {
			loading: true,
			dialog: false,
			heads: []
		}
	},

	async mounted() {
		await this.fetchHeads();
	},

	methods: {
		async UpdateDVs () {
			this.dialog = false;
			await this.fetchHeads();
		},

		async fetchHeads () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/dashviews`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				const res = await request.json();

				if (request.ok) {
					if (res.heads) this.heads = res.heads;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: 'MG Network',
					text: error.message
				});
			}
			finally {
				this.loading = false;
			}
		},
	},
}
</script>