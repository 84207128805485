<template>
	<v-card>
		<v-form class="pa-2" @submit.prevent="SaveDV">
			<v-card-title>
				<span class="text-h5 text-center">New Dashboard Banner Info</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field 
								clearable
								v-model.trim="$v.title.$model"
								:error-messages="titleErrors"
								@input="$v.title.$touch()"
								@blur="$v.title.$touch()"
								label="Title*" 
								required
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field 
								clearable
								v-model="extra_title"
								label="Extra Sub Title" 
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-textarea
								clearable
								v-model="desc"
								label="Description" 
							></v-textarea>
						</v-col>

						<v-col cols="12">
							<v-text-field
								clearable
								v-model.trim="$v.img.$model"
								:error-messages="imgErrors"
								@input="$v.img.$touch()"
								@blur="$v.img.$touch()"
								label="Dashboard banner image URL*" 
								required
							></v-text-field>
							<v-img :src="img" max-width="600" transition="fade-transition" contain></v-img>
						</v-col>

					</v-row>
				</v-container>
				<small>* indicates required field</small>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="$emit('CloseDialog')">Close</v-btn>
				<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
			</v-card-actions>
		</v-form>
    </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			loadingBtn: false,
			desc: '',
			extra_title: '',
			title: '',
			img: ''
		}
	},

	validations() {
		return {
			title: {
				required
			},
			img: {
				required
			},
		};
	},

	computed: {
		titleErrors() {
			const errors = [];
			if (!this.$v.title.$dirty) return errors;
			!this.$v.title.required && errors.push('Title is required.');
			return errors;
		},

		imgErrors() {
			const errors = [];
			if (!this.$v.img.$dirty) return errors;
			!this.$v.img.required && errors.push('Image URL is required.');
			return errors;
		}
	},

	methods: {
		async SaveDV () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;

				try {
					const request = await fetch(`${this.$store.state.base_url}/dashviews/new`, {
						method: 'POST',
						body: JSON.stringify({
							desc: this.desc,
							extra_title: this.extra_title,
							title: this.title,
							img: this.img
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('mgn_sessid'),
						}
					});

					const res = await request.json();
					
					if (request.ok) {	
						if (res.dv_saved) {
							this.$v.title.$reset();
							this.$v.img.$reset();
							this.title = '';
							this.extra_title = '';
							this.description = '';
							this.img = '';

							this.loadingBtn = false;

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Saved successfully!'
							});

							this.$emit('dvAdded');
						}
					}
					else if (request.status === 401) {
						res.forEach((msg) => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: msg.error
							});
						});

						this.loadingBtn = false;
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error
					});
				}
			}
		}
	},
}
</script>