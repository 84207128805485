<template>
	<v-card flat outlined class="rounded-xl d-flex flex-column text-center rewards_card">
		<v-img :src="head.img" height="200" transition="fade-transition">
			<v-col class="pa-0" cols="12">
				<v-card-title class="mgn_header rounded-xl rounded-b-0">
					{{ title }}
				</v-card-title>
			</v-col>
		</v-img>

		<v-card-actions class="my-2">
			<v-container>
				<v-row class="d-flex justify-end">

				<v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogEditDashView">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="primary" class="mx-1" elevation="0" icon large>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</template>

					<v-card class="pa-5">
						<v-app-bar fixed tile color="dark">
							<v-btn icon @click="dialogEditDashView = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
							<v-toolbar-title>{{ title }}</v-toolbar-title>

							<v-spacer></v-spacer>
							<v-btn color="primary" large :loading='loadingSaveBtn' :disabled='loadingSaveBtn' @click="UpdateDashView()">
								<v-icon left>mdi-content-save</v-icon>Save
							</v-btn>
						</v-app-bar>

						<v-container class="pt-16 d-flex align-stretch">
							<v-row class="ma-2">
								<v-col cols="12">
									<h3 class="mb-7">Main Info</h3>

									<v-text-field
										clearable
										outlined
										:value="title"
										v-model.trim="$v.title.$model"
										:error-messages="titleErrors"
										@input="$v.title.$touch()"
										@blur="$v.title.$touch()"
										label="Title*"
										required
									></v-text-field>

									<v-text-field
										clearable
										outlined
										v-model="extra_title"
										:value="extra_title"
										label="Extra Title"
										required
									></v-text-field>

									<v-textarea
										outlined
										clearable
										no-resize
										rows="6"
										v-model="desc"
										:value="desc"
										label="Description"
									></v-textarea>
								</v-col>
							</v-row>

							<v-divider vertical class="ma-5"></v-divider>

							<v-row class="ma-2">
								<v-col cols="12" class="justify-center">
									<h3 class="mb-7">Image</h3>

									<v-text-field
										v-model.trim="$v.img.$model"
										:error-messages="imgErrors"
										@input="$v.img.$touch()"
										@blur="$v.img.$touch()"
										:value="img"
										label="Dashboard banner image URL*"
										clearable
									></v-text-field>
									<v-img :src="img" max-width="600" contain></v-img>
								</v-col>
							</v-row>

						</v-container>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogDelete" max-width="450px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="error" class="mx-1" elevation="0" icon large>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>

					<v-card>
						<v-card-title>
							<v-icon class="mr-2">mdi-alert</v-icon>
							{{ title }}
						</v-card-title>

						<v-card-text>
							Are you sure you want to delete this DashView?
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="secondary" @click="dialogDelete = false">Cancel</v-btn>
							<v-btn color="error" :loading="loadingDeleteBtn" :disabled="loadingDeleteBtn" @click="DeleteDashView(id)">Delete</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>

			</v-row>
			</v-container>
		</v-card-actions>
	</v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	props: ['head'],

	data() {
		return {
			dialogEditDashView: false,
			dialogDelete: false,
			loadingSaveBtn: false,
			loadingDeleteBtn: false,
			id: this.head.id,
			desc: this.head.desc,
			extra_title: this.head.extra_head,
			title: this.head.head,
			img: this.head.img
		}
	},

	validations: {
		title: {
			required
		},
		img: {
			required
		},
	},

	computed: {
		titleErrors() {
			const errors = [];
			if (!this.$v.title.$dirty) return errors;
			!this.$v.title.required && errors.push('Title is required.');
			return errors;
		},

		imgErrors() {
			const errors = [];
			if (!this.$v.img.$dirty) return errors;
			!this.$v.img.required && errors.push('Image URL is required.');
			return errors;
		}
	},

	methods: {
		async UpdateDashView () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				try {
					this.loadingSaveBtn = true;

					const request = await fetch(`${this.$store.state.base_url}/dashviews/update`, {
						method: 'PUT',
						body: JSON.stringify({
							id: this.id,
							desc: this.desc,
							extra_title: this.extra_title,
							title: this.title,
							img: this.img
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('mgn_sessid'),
						}
					});

					const res = await request.json();

					if (request.ok && request.status === 200) {	
						if (res.dv_updated) {
							this.$emit('dvUpdated');

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Updated successfully!'
							});

							this.dialogEditDashView = false;
						}
						else if (res.dv_no_change) {
							this.$notify({
								group: 'main',
								type: 'warn',
								title: this.$store.state.app_name,
								text: 'No changes made.'
							});

							this.dialogEditDashView = false;
						}
					}
					else if (request.status === 401) {
						res.forEach(e => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: e.error
							});
						});
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: 'There was an unexpected error :('
					});
				}
				finally {
					this.loadingSaveBtn = false;
				}
			}
		},

		async DeleteDashView (id) {
			try {
				this.loadingDeleteBtn = true;

				const request = await fetch(`${this.$store.state.base_url}/dashviews/delete`, {
					method: 'DELETE',
					body: JSON.stringify({
						id: id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});

				const res = await request.json();

				if (request.ok && request.status === 200) {	
					if (res.dv_deleted) {
						this.dialogDelete = false;
						this.$emit('dvDeleted');

						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Deleted successfully!'
						});
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was an unexpected error. Please try later.'
				});
			}
			finally {
				this.loadingDeleteBtn = false;
			}
		}
	},
}
</script>